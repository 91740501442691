const chartData = [
  {
    subject: "Full Body",
    A: 75,
    B: 90,
    fullMark: 100,
  },
  {
    subject: "Tannin",
    A: 58,
    B: 70,
    fullMark: 100,
  },
  {
    subject: "Acidity",
    A: 86,
    B: 80,
    fullMark: 100,
  },
  {
    subject: "Alcohol",
    A: 70,
    B: 100,
    fullMark: 100,
  },
  {
    subject: "Fruit",
    A: 75,
    B: 90,
    fullMark: 100,
  },
  {
    subject: "Price",
    A: 65,
    B: 85,
    fullMark: 100,
  },
]

const baseUnit = 4

export default {
  baseUnit: baseUnit,
  chartData: chartData,
}
