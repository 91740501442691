import styled from "styled-components"

// Components
import Constants from "../other/constants"
const base = Constants.baseUnit

const MainWrapper = styled.main`
  margin: 0 auto;
  padding: 0 0 ${base * 20}px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(12, auto);
  grid-gap: ${base * 12}px;
  width: 1120px;

  @media (max-width: 1200px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
  }
`

export default MainWrapper
