exports.components = {
  "component---src-components-camera-roll-js": () => import("./../../../src/components/camera-roll.js" /* webpackChunkName: "component---src-components-camera-roll-js" */),
  "component---src-components-detail-js": () => import("./../../../src/components/detail.js" /* webpackChunkName: "component---src-components-detail-js" */),
  "component---src-components-post-js": () => import("./../../../src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-components-story-js": () => import("./../../../src/components/story.js" /* webpackChunkName: "component---src-components-story-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-stock-js": () => import("./../../../src/pages/stock.js" /* webpackChunkName: "component---src-pages-stock-js" */),
  "component---src-pages-testing-js": () => import("./../../../src/pages/testing.js" /* webpackChunkName: "component---src-pages-testing-js" */)
}

