import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

// Utility
import Constants from "../other/constants"
const base = Constants.baseUnit

const Wrapper = styled.div`
  margin: 0;
  grid-row: 1;
  grid-column: 1 / 9;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    justify-content: space-between;
    padding: 0 ${base * 12}px;
  }

  @media (max-width: 600px) {
    padding: 0 ${base * 6}px;
  }
`

const Item = styled.div`
  padding: ${base * 8}px 0;
  flex-basis: 25%;
  align-self: flex-end;
  color: rgba(0, 0, 0, 0.8);
  text-align: right;
  
  a {
    padding: ${base * 8}px 0;
    font-family: "ITC Avant Garde Gothic Bold";
    font-size: 14px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    line-height: ${base * 8}px;

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.8);
    }

    @media (max-width: 800px) {
      font-size: 12px;
    }

    @media (max-width: 322px) {
      font-size: 10px;
    }

    &:hover {
      color: rgba(0, 0, 0, 1);
      text-decoration: none;

      @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, 1);
      }
    }

    &.activeNav {
      padding: ${base * 8}px 0;
      color: rgba(0, 0, 0, 1);

      @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

   @media (max-width: 1000px) {
    flex-basis: auto;
  }

  &.main {
    text-align: left;
    flex-basis: 75%;

    @media (max-width: 1000px) {
      flex-basis: auto;
   }
`

const links = [
  { url: "/", text: "Giovanni", classy: "main" },
  { url: "/camera-roll", text: "Camera Roll" },
  { url: "/photos", text: "Photos" },
]

const Navigation = () => (
  <Wrapper>
    {links.map(({ url, text, classy }) => (
      <Item key={url} className={classy}>
        <Link to={url} activeClassName={"activeNav"}>
          {text}
        </Link>
      </Item>
    ))}
  </Wrapper>
)

Navigation.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  classy: PropTypes.string,
}

export default Navigation
